export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "*Required fields": "*Required fields",
        "404 - Page not found": "404 - Page not found",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is invalid",
        "{count} characters": "{count} characters",
        "{model} {action}": "{model} {action}",
        "About us": "About us",
        "Accepted": "Accepted",
        "Account": "Account",
        "Activated": "activated",
        "activated": "activated",
        "Active": "Active",
        "Add a underline to all titles": "Add a underline to all titles",
        "Add comment": "Add comment",
        "Add message": "Add message",
        "Add page block": "Add page block",
        "Add to cart": "Add to cart",
        "All": "All",
        "All {models}": "All {models}",
        "All projects (Including filter and pagination)": "All projects (Including filter and pagination)",
        "All rights reserved.": "All rights reserved.",
        "All vacancies (Including filter and pagination)": "All vacancies (Including filter and pagination)",
        "Already Reported": "Already Reported",
        "Alt text": "Alt text",
        "Amount projects": "Amount projects",
        "Amount vacancies": "Amount vacancies",
        "Answer": "Answer",
        "A piece": "A piece",
        "Apply directly": "Apply directly",
        "Apply discount code": "Apply discount code",
        "Apply now": "Apply now",
        "A Timeout Occurred": "A Timeout Occurred",
        "Author": "Author",
        "Back": "Back",
        "Back and mark as done": "Back and mark as done",
        "Back and release": "Back and release",
        "Back to home": "Back to home",
        "Backup codes": "Backup codes",
        "Bad Gateway": "Bad Gateway",
        "Bad Request": "Bad Request",
        "Bandwidth Limit Exceeded": "Bandwidth Limit Exceeded",
        "Banner": "Banner",
        "Birthday *": "Birthday *",
        "Blogs": "Blogs",
        "Blog Settings": "Blog Settings",
        "Body color": "Body color",
        "Bold": "Bold",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Button corners": "Button corners",
        "Button label": "Button label",
        "Buttons": "Buttons",
        "Checkout page": "Checkout page",
        "Choose file": "Choose file",
        "City": "City",
        "Client": "Client",
        "Client Closed Request": "Client Closed Request",
        "Clients": "Clients",
        "Close conversation": "Close conversation",
        "closed": "closed",
        "code": "code",
        "Comment": "Comment",
        "Company name": "Company name",
        "Complete": "Complete",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in only visible to logged in users, draft is not visible to anyone",
        "Conflict": "Conflict",
        "Connection Closed Without Response": "Connection Closed Without Response",
        "Connection Timed Out": "Connection Timed Out",
        "Consultant": "Consultant",
        "Contact": "Contact",
        "Contactform": "Contactform",
        "Content": "Content",
        "Continue": "Continue",
        "Conversation": "Conversation",
        "Conversations": "Conversations",
        "Create {model}": "Create {model}",
        "created": "created",
        "Created": "Created",
        "Created at": "Created at",
        "Create follow-up": "Create follow-up",
        "Create order": "Create order",
        "Dangerously high": "Dangerously high",
        "Dangerously high explanation": "Dangerously high explanation",
        "Dangerously Low": "Dangerously Low",
        "Dangerously low explanation": "Dangerously low explanation",
        "Date": "Date",
        "Date of birth": "Date of birth",
        "Deactivate": "Deactivate",
        "Deactivated": "deactivated",
        "deactivated": "deactivated",
        "Default consultant": "Default consultant",
        "deleted": "deleted",
        "Delete filters": "Delete filters",
        "Description": "Description",
        "description": "description",
        "Details": "Details",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Discount code": "Discount code",
        "done": "done",
        "Down": "Down",
        "Download app": "Download app",
        "Download backup codes and continue.": "Download backup codes and continue.",
        "Due at": "Due at",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "Edit {model}": "Edit {model}",
        "Edit account": "Edit account",
        "Email": "Email",
        "email": "email",
        "End": "End",
        "Enter working hours here": "Enter working hours here",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "Expectation Failed": "Expectation Failed",
        "External Test": "External Test",
        "External Tester": "External Tester",
        "External URL": "External URL",
        "Failed Dependency": "Failed Dependency",
        "Fill in the 6-digit code": "Fill in the 6-digit code",
        "First name": "First name",
        "First name *": "First name *",
        "Follow up to": "Follow up to",
        "Follow up tot": "Follow up tot",
        "Font": "Font",
        "Font size": "Font size",
        "Font weight": "Font weight",
        "Footer color": "Footer color",
        "Forbidden": "Forbidden",
        "Found": "Found",
        "From the authenticator app": "From the authenticator app",
        "Fully rounded": "Fully rounded",
        "Gallery": "Gallery",
        "Gateway Timeout": "Gateway Timeout",
        "Global": "Global",
        "Gone": "Gone",
        "Google Authenticator": "Google Authenticator",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Go to page {page}",
        "Heading": "Heading",
        "Heading 1 is the main title and will be used on every page once": "Heading 1 is the main title and will be used on every page once",
        "Heading 2 are the subtitles and can be used multiple times on a page": "Heading 2 are the subtitles and can be used multiple times on a page",
        "Hello": "Hello",
        "Hello!": "Hello!",
        "Here you can turn on extra modules that are not included in the standard basic MediaMix template. Those are paid modules.": "Here you can turn on extra modules that are not included in the standard basic MediaMix template. Those are paid modules.",
        "High": "High",
        "High explanation": "High explanation",
        "Hits": "Hits",
        "Hours": "Hours",
        "HTTP Version Not Supported": "HTTP Version Not Supported",
        "I'm a teapot": "I'm a teapot",
        "I agree with the": "I agree with the",
        "I agree with the terms and conditions*": "I agree with the terms and conditions*",
        "Icon": "Icon",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "I have saved the backup codes": "I have saved the backup codes",
        "Image": "Image",
        "IM Used": "IM Used",
        "Including VAT": "Including VAT",
        "Index Page": "Index Page",
        "Inloggen": "Inloggen",
        "Inspection": "Inspection",
        "Instruction title": "Instruction title",
        "Insufficient Storage": "Insufficient Storage",
        "Internal comment": "Internal comment",
        "Internal comments": "Internal comments",
        "Internal Server Error": "Internal Server Error",
        "Introduction": "Introduction",
        "Invalid phone number": "Invalid phone number",
        "Invalid SSL Certificate": "Invalid SSL Certificate",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "Job position": "Job position",
        "Label Button": "Label Button",
        "Last message on": "Last message on",
        "Last name": "Last name",
        "Last name *": "Last name *",
        "Last refresh was {quantity} {unit} ago": "Last refresh was {quantity} {unit} ago",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Length Required": "Length Required",
        "Light": "Light",
        "Line height": "Line height",
        "Link Button": "Link Button",
        "Locked": "Locked",
        "Login": "Login",
        "Logoslider": "Logoslider",
        "Logout": "Logout",
        "Loop Detected": "Loop Detected",
        "Low": "Low",
        "Low explanation": "Low explanation",
        "Made by:": "Made by:",
        "Maintenance Mode": "Maintenance Mode",
        "Mark as done": "Mark as done",
        "Medical": "Medical",
        "Message": "Message",
        "Message *": "Message *",
        "Messages": "Messages",
        "Method Not Allowed": "Method Not Allowed",
        "Metric Name": "Metric Name",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "minus icon": "minus icon",
        "Misdirected Request": "Misdirected Request",
        "Modules": "Modules",
        "More settings can be set in the Page Block Settings page": "More settings can be set in the Page Block Settings page",
        "Moved Permanently": "Moved Permanently",
        "Multi-Status": "Multi-Status",
        "Multiple Choices": "Multiple Choices",
        "Mute client": "Mute client",
        "Muted": "Muted",
        "muted": "muted",
        "Name": "Name",
        "name": "name",
        "Name *": "Name *",
        "Name client": "Name client",
        "Network Authentication Required": "Network Authentication Required",
        "Network Connect Timeout Error": "Network Connect Timeout Error",
        "Network Read Timeout Error": "Network Read Timeout Error",
        "Newsletter": "Newsletter",
        "New URL": "New URL",
        "No": "No",
        "No Content": "No Content",
        "No items": "No items",
        "Non-Authoritative Information": "Non-Authoritative Information",
        "None": "None",
        "Normal": "Normal",
        "Not Acceptable": "Not Acceptable",
        "Not Extended": "Not Extended",
        "Not Found": "Not Found",
        "Not Implemented": "Not Implemented",
        "Not implemented yet": "Not implemented yet",
        "Not Modified": "Not Modified",
        "of": "of",
        "OK": "OK",
        "Old URL": "Old URL",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.",
        "Opening hours": "Opening hours",
        "Openings hours": "Openings hours",
        "Open the authenticator app, add a new entry and scan the QR code": "Open the authenticator app, add a new entry and scan the QR code",
        "Order": "Order",
        "Origin Is Unreachable": "Origin Is Unreachable",
        "Overview": "Overview",
        "Package name": "Package name",
        "Packages": "Packages",
        "Page (within website)": "Page (within website)",
        "Page blocks": "Page blocks",
        "Page Expired": "Page Expired",
        "PageHeader": "PageHeader",
        "Page Title": "Page Title",
        "Pagination Navigation": "Pagination Navigation",
        "Paragraph": "Paragraph",
        "Parent category": "Parent category",
        "Partial Content": "Partial Content",
        "Password": "Password",
        "Paste in here only the Youtube ID. That id the ID what you see in the url in the browser after https://www.youtube.com/watch?v=": "Paste in here only the Youtube ID. That id the ID what you see in the url in the browser after https://www.youtube.com/watch?v=",
        "Paste or type the code we sent a code to your e-mail.": "Paste or type the code we sent a code to your e-mail.",
        "Paste or type the code we sent a code to your phone number.": "Paste or type the code we sent a code to your phone number.",
        "Payload Too Large": "Payload Too Large",
        "Payment Required": "Payment Required",
        "Permanent": "Permanent",
        "Permanent Redirect": "Permanent Redirect",
        "Phone": "Phone",
        "Phone *": "Phone *",
        "Phone number": "Phone number",
        "phone number": "phone number",
        "phone_number": "phone_number",
        "Place": "Place",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "plus icon": "plus icon",
        "Postal code": "Postal code",
        "Postcode": "Postcode",
        "Precondition Failed": "Precondition Failed",
        "Precondition Required": "Precondition Required",
        "Price": "Price",
        "Price frequency": "Price frequency",
        "Primary Color": "Primary Color",
        "Print": "Print",
        "Priority": "Priority",
        "Privacy declaration": "Privacy declaration",
        "Processing": "Processing",
        "Product": "Product",
        "Products": "Products",
        "Project Content": "Project Content",
        "Projects": "Projects",
        "Proxy Authentication Required": "Proxy Authentication Required",
        "Question": "Question",
        "Railgun Error": "Railgun Error",
        "Range Not Satisfiable": "Range Not Satisfiable",
        "Reactivate": "Reactivate",
        "Read more": "Read more",
        "Regards": "Regards",
        "Register": "Register",
        "Regular": "Regular",
        "Related products": "Related products",
        "Remove": "Remove",
        "Remove discount code": "Remove discount code",
        "Reopened subtask": "Reopened subtask",
        "Request Header Fields Too Large": "Request Header Fields Too Large",
        "Request Timeout": "Request Timeout",
        "Resend {attribute}": "Resend {attribute}",
        "Reserved By": "Reserved By",
        "Reset Content": "Reset Content",
        "Reset Password": "Reset Password",
        "Reset Password Notification": "Reset Password Notification",
        "results": "results",
        "Results": "results",
        "Results are shared": "Results are shared",
        "Retry With": "Retry With",
        "Review": "Review",
        "Reviews": "Reviews",
        "Review Slider": "Review Slider",
        "Reviewslider": "Reviewslider",
        "Role": "Role",
        "role": "role",
        "Roles": "Roles",
        "Rounded": "Rounded",
        "Sampled at": "Sampled at",
        "Save": "Save",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Save these codes on a secure place if you can’t get access with your Authenticator app.",
        "Scanning not possible? Fill in this code in the app.": "Scanning not possible? Fill in this code in the app.",
        "Scan the QR code": "Scan the QR code",
        "Search": "Search",
        "Secondary Color": "Secondary Color",
        "See all faqs": "See all faqs",
        "See Other": "See Other",
        "Select a {model}": "Select a {model}",
        "Select a template to add the corresponding blocks.": "Select a template to add the corresponding blocks.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created",
        "Select a test": "Select a test",
        "Select a type": "Select a type",
        "Send application": "Send application",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Session Has Expired": "Session Has Expired",
        "Set all titles to uppercase": "Set all titles to uppercase",
        "Set color for the footer.": "Set color for the footer.",
        "Sets": "Sets",
        "Set Two Factor Authentication": "Set Two Factor Authentication",
        "shared": "shared",
        "Share results": "Share results",
        "Shipping address same as billing address": "Shipping address same as billing address",
        "Shipping method": "Shipping method",
        "Shop": "Shop",
        "Shop not finished yet...": "Shop not finished yet...",
        "Showing": "Showing",
        "Sign out": "Sign out",
        "Slug": "Slug",
        "Social media": "Social media",
        "SSL Handshake Failed": "SSL Handshake Failed",
        "Stars": "Stars",
        "Start": "Start",
        "Start date:": "Start date:",
        "Started at": "Started at",
        "Status": "Status",
        "Statuses": "Statuses",
        "Street name": "Street name",
        "Street number": "Street number",
        "Street number addition": "Street number addition",
        "Subject *": "Subject *",
        "Submit": "Submit",
        "SubTasks": "SubTasks",
        "Subtotal": "Subtotal",
        "Switching Protocols": "Switching Protocols",
        "Task": "Task",
        "Tasks": "Tasks",
        "Temporary Redirect": "Temporary Redirect",
        "Terms and Conditions": "Terms and Conditions",
        "terms and conditions": "terms and conditions",
        "Tertiary Color": "Tertiary Color",
        "Test": "Test",
        "test": "test",
        "Tests": "Tests",
        "Test set": "Test set",
        "test set": "test set",
        "Test sets": "Test sets",
        "Text": "Text",
        "Text transform": "Text transform",
        "Thank you for your application!": "Thank you for your application!",
        "Thank you for your message!": "Thank you for your message!",
        "Thank you for your sign up!": "Thank you for your sign up!",
        "The {attribute} has already been taken.": "The {attribute} has already been taken.",
        "The {attribute} must be verified.": "The {attribute} must be verified.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The coupon has already been used.": "The coupon has already been used.",
        "The coupon has expired.": "The coupon has expired.",
        "The font size for the main / body font.": "The font size for the main / body font.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "The provided {attribute} is invalid.": "The provided {attribute} is invalid.",
        "The provided code was invalid.": "The provided code was invalid.",
        "The provided password does not match your current password.": "The provided password does not match your current password.",
        "The provided two factor authentication code was invalid.": "The provided two factor authentication code was invalid.",
        "The styling for all mails like contactform, newsletter etc.": "The styling for all mails like contactform, newsletter etc.",
        "The tests cannot be changed once the test set is created.": "The tests cannot be changed once the test set is created.",
        "The thickness of the font that will be displayed": "The thickness of the font that will be displayed",
        "The thickness of the font that will be displayed for all heading titles": "The thickness of the font that will be displayed for all heading titles",
        "This {attribute} has already been used": "This {attribute} has already been used",
        "This area is restricted to medical medic members only.": "This area is restricted to medical medic members only.",
        "This area is restricted to staff members only.": "This area is restricted to staff members only.",
        "This font will be used as main / body font.": "This font will be used as main / body font.",
        "This font will be used for all heading titles H1, H2, H3. H4, H5, H6": "This font will be used for all heading titles H1, H2, H3. H4, H5, H6",
        "This is your first login": "This is your first login",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "This task is reserved by {userName}": "This task is reserved by {userName}",
        "This task is reserved by {userName}.": "This task is reserved by {userName}.",
        "Title": "Title",
        "title": "title",
        "to": "to",
        "TODO": "TODO",
        "Toggle navigation": "Toggle navigation",
        "token": "token",
        "Too Early": "Too Early",
        "Too Many Requests": "Too Many Requests",
        "Total": "Total",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now",
        "Trans": "Trans",
        "Two Factor Authentication - Backup codes": "Two Factor Authentication - Backup codes",
        "Type": "Type",
        "Type job position": "Type job position",
        "Unauthorized": "Unauthorized",
        "Unavailable For Legal Reasons": "Unavailable For Legal Reasons",
        "Underline": "Underline",
        "Unknown Error": "Unknown Error",
        "Unmute client": "Unmute client",
        "unmuted": "unmuted",
        "Unprocessable Entity": "Unprocessable Entity",
        "Unsupported Media Type": "Unsupported Media Type",
        "Up": "Up",
        "updated": "updated",
        "Upgrade Required": "Upgrade Required",
        "Upload C.V *": "Upload C.V *",
        "Upload Motivation *": "Upload Motivation *",
        "Uppercase": "Uppercase",
        "URI Too Long": "URI Too Long",
        "url": "url",
        "Use Proxy": "Use Proxy",
        "User": "User",
        "user": "user",
        "Users": "Users",
        "USPs": "USPs",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Vacancies": "Vacancies",
        "Vacancy Content": "Vacancy Content",
        "Vacancyfilters": "Vacancyfilters",
        "Variant Also Negotiates": "Variant Also Negotiates",
        "Variants": "Variants",
        "VAT": "VAT",
        "Verify": "Verify",
        "Verify {attribute}": "Verify {attribute}",
        "Verify Email Address": "Verify Email Address",
        "Videos": "Videos",
        "View project": "View project",
        "View vacancy": "View vacancy",
        "Web Page": "Web Page",
        "Web Page Settings": "Web Page Settings",
        "Web Server is Down": "Web Server is Down",
        "weekly": "weekly",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We have received your message. One of our employees will contact you as soon as possible.",
        "Welcome": "Welcome",
        "Welcome to {page}": "Welcome to {page}",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "Whoops!": "Whoops!",
        "Will be used as main color for buttons, links, elements or components with a background color": "Will be used as main color for buttons, links, elements or components with a background color",
        "Will be used as main color for the body text": "Will be used as main color for the body text",
        "Yes": "Yes",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "Your account for {siteName}": "Your account for {siteName}",
        "Your account is inactive. Please contact support.": "Your account is inactive. Please contact support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.",
        "Your current locale is {locale}": "Your current locale is {locale}",
        "Your last login was {relativeTime} on {dateTime}": "Your last login was {relativeTime} on {dateTime}",
        "Your Order": "Your Order",
        "Your order": "Your order",
        "Your Profile": "Your Profile",
        "Youtube": "Youtube",
        "Youtube Video ID": "Youtube Video ID",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "ascii": "The {attribute} must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "decimal": "The {attribute} must have {decimal} decimal places.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} may not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field is required.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "string": "The {attribute} must be greater than or equal {value} characters."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal {value}.",
                "string": "The {attribute} must be less than or equal {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} may not have more than {max} items.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "numeric": "The {attribute} may not be greater than {max}.",
                "string": "The {attribute} may not be greater than {max} characters."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} must contain at least one letter.",
                "mixed": "The {attribute} must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} must contain at least one number.",
                "symbols": "The {attribute} must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "ulid": "The {attribute} must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "affiliate_url": "affiliate URL",
                "age": "age",
                "amount": "amount",
                "announcement": "announcement",
                "area": "area",
                "audience_prize": "audience prize",
                "audience_winner": "audience winner",
                "available": "available",
                "billing_address.city": "city",
                "billing_address.country": "country",
                "billing_address.postcode": "postcode",
                "billing_address.street_name": "street name",
                "billing_address.street_number": "street number",
                "billing_address.street_number_addition": "street number addition",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "company": "company",
                "compilation": "compilation",
                "concept": "concept",
                "conditions": "conditions",
                "consultant_id": "consultant",
                "content": "content",
                "contest": "contest",
                "country": "country",
                "cover": "cover",
                "created_at": "created at",
                "creator": "creator",
                "currency": "currency",
                "current_password": "current password",
                "customer": "customer",
                "date": "date",
                "date_of_birth": "date of birth",
                "dates": "dates",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "display_type": "display type",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "finished_at": "finished at",
                "first_name": "first name",
                "gender": "gender",
                "grand_prize": "grand prize",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "image_desktop": "desktop image",
                "image_main": "main image",
                "image_mobile": "mobile image",
                "images": "images",
                "is_audience_winner": "is audience winner",
                "is_hidden": "is hidden",
                "is_subscribed": "is subscribed",
                "is_visible": "is visible",
                "is_winner": "is winner",
                "items": "items",
                "key": "key",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "login": "login",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "portfolio": "portfolio",
                "postal_code": "postal code",
                "preview": "preview",
                "price": "price",
                "product_id": "product ID",
                "product_uid": "product UID",
                "product_uuid": "product UUID",
                "promo_code": "promo code",
                "province": "province",
                "quantity": "quantity",
                "reason": "reason",
                "recaptcha_response_field": "recaptcha response field",
                "referee": "referee",
                "referees": "referees",
                "reject_reason": "reject reason",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "role_id": "role",
                "rule": "rule",
                "rules": "rules",
                "second": "second",
                "sex": "sex",
                "shipment": "shipment",
                "shipping_address.city": "city",
                "shipping_address.country": "country",
                "shipping_address.postcode": "postcode",
                "shipping_address.street_name": "street name",
                "shipping_address.street_number": "street number",
                "shipping_address.street_number_addition": "street number addition",
                "shipping_rate_id": "shipping rate",
                "short_text": "short text",
                "size": "size",
                "skills": "skills",
                "slug": "slug",
                "specialization": "specialization",
                "started_at": "started at",
                "state": "state",
                "status": "status",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "tag": "tag",
                "tags": "tags",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "type": "type",
                "updated_at": "updated at",
                "user": "user",
                "username": "username",
                "value": "value",
                "winner": "winner",
                "work": "work",
                "year": "year"
            }
        },
        "routes": {
            "blog": "blog",
            "projects": "projects",
            "about-us": "about-us",
            "general-terms": "general-terms",
            "privacy-policy": "privacy-policy",
            "cookie-policy": "cookie-policy",
            "contact": "contact",
            "products": "products",
            "vacancies": "vacancies",
            "faq": "faq",
            "team": "team",
            "news": "news"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "http-statuses": {
            "0": "Unknown Error",
            "100": "Continue",
            "101": "Switching Protocols",
            "102": "Processing",
            "200": "OK",
            "201": "Created",
            "202": "Accepted",
            "203": "Non-Authoritative Information",
            "204": "No Content",
            "205": "Reset Content",
            "206": "Partial Content",
            "207": "Multi-Status",
            "208": "Already Reported",
            "226": "IM Used",
            "300": "Multiple Choices",
            "301": "Moved Permanently",
            "302": "Found",
            "303": "See Other",
            "304": "Not Modified",
            "305": "Use Proxy",
            "307": "Temporary Redirect",
            "308": "Permanent Redirect",
            "400": "Bad Request",
            "401": "Unauthorized",
            "402": "Payment Required",
            "403": "Forbidden",
            "404": "Not Found",
            "405": "Method Not Allowed",
            "406": "Not Acceptable",
            "407": "Proxy Authentication Required",
            "408": "Request Timeout",
            "409": "Conflict",
            "410": "Gone",
            "411": "Length Required",
            "412": "Precondition Failed",
            "413": "Payload Too Large",
            "414": "URI Too Long",
            "415": "Unsupported Media Type",
            "416": "Range Not Satisfiable",
            "417": "Expectation Failed",
            "418": "I'm a teapot",
            "419": "Session Has Expired",
            "421": "Misdirected Request",
            "422": "Unprocessable Entity",
            "423": "Locked",
            "424": "Failed Dependency",
            "425": "Too Early",
            "426": "Upgrade Required",
            "428": "Precondition Required",
            "429": "Too Many Requests",
            "431": "Request Header Fields Too Large",
            "444": "Connection Closed Without Response",
            "449": "Retry With",
            "451": "Unavailable For Legal Reasons",
            "499": "Client Closed Request",
            "500": "Internal Server Error",
            "501": "Not Implemented",
            "502": "Bad Gateway",
            "503": "Maintenance Mode",
            "504": "Gateway Timeout",
            "505": "HTTP Version Not Supported",
            "506": "Variant Also Negotiates",
            "507": "Insufficient Storage",
            "508": "Loop Detected",
            "509": "Bandwidth Limit Exceeded",
            "510": "Not Extended",
            "511": "Network Authentication Required",
            "520": "Unknown Error",
            "521": "Web Server is Down",
            "522": "Connection Timed Out",
            "523": "Origin Is Unreachable",
            "524": "A Timeout Occurred",
            "525": "SSL Handshake Failed",
            "526": "Invalid SSL Certificate",
            "527": "Railgun Error",
            "598": "Network Read Timeout Error",
            "599": "Network Connect Timeout Error",
            "unknownError": "Unknown Error"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We have emailed your password reset link!"
        }
    },
    "nl": {
        "(and {count} more error)": "(en {count} andere foutmelding)",
        "(and {count} more errors)": "(en {count} andere foutmeldingen)",
        "*Required fields": "*Verplichte velden",
        "1 star": "1 ster",
        "2 stars": "2 sterren",
        "3 stars": "3 sterren",
        "4 stars": "4 sterren",
        "5 stars": "5 sterren",
        "8-12 hours": "8-12 uur",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is ongeldig",
        "{count} characters": "{count} characters",
        "{model} {action}": "{model} {action}",
        "About HGBI": "Over HGBI",
        "About the cookies on this website": "Over de cookies op deze website",
        "About us": "Over ons",
        "Accepted": "Aanvaard",
        "Account": "Account",
        "Activated": "Geactiveerd",
        "activated": "geactiveerd",
        "Active": "Actief",
        "Add a underline to all titles": "Maak alle titels onderstreept",
        "Add menu item": "Menu-item toevoegen",
        "Add message": "Bericht toevoegen",
        "Add page block": "Pagina blok toevoegen",
        "Add phone number in global template options": "Telefoonnummer toevoegen in globale sjabloonopties",
        "All": "Alles",
        "All {models}": "Alle {modellen}",
        "All news (Including filter and pagination)": "Alle nieuws (inclusief filter en paginering)",
        "All projects (Including filter and pagination)": "Alle projecten (Inclusief filters en paginatie)",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "All team members (Including pagination)": "Alle teamleden (inclusief paginering)",
        "All vacancies (Including filter and pagination)": "Alle vacatures (Inclusief filters en paginatie)",
        "Already Reported": "Al gemeld",
        "Alt text": "Alt-tekst",
        "Amount Blogs": "Bedrag Blogs",
        "Amount news": "Bedrag nieuws",
        "Amount projects": "Hoeveelheid projecten",
        "Amount team members": "Bedrag teamleden",
        "Amount vacancies": "Hoeveelheid vacatures",
        "Answer": "Antwoord",
        "any": "enkele",
        "A piece": "Per stuk",
        "Apply directly": "Solliciteer nu",
        "Apply discount code": "Kortingscode toepassen",
        "Apply now": "Solliciteer nu",
        "A Timeout Occurred": "Time-out opgetreden",
        "Author": "Auteur",
        "Author:": "Auteur:",
        "Back": "Terug",
        "Back and mark as done": "Terug en markeer als klaar",
        "Back and release": "Terug en loslaten",
        "Background color": "Achtergrondkleur",
        "Background image": "Achtergrondafbeelding",
        "Back to home": "Terug naar home",
        "Backup codes": "Back-up codes",
        "Bad Gateway": "Slechte poort",
        "Bad Request": "Foute aanvraag",
        "Bandwidth Limit Exceeded": "Bandbreedte overschreden",
        "Banner": "Banner",
        "Banner (Slider Variant)": "Banner (schuifvariant)",
        "Birthday *": "Geboortedatum *",
        "Black": "Zwart",
        "Blog": "Blog",
        "Blog Content": "Blog inhoud",
        "Blogs": "Blogs",
        "Blogs (Including filter and pagination)": "Blogs (inclusief filter en paginering)",
        "Blog Settings": "Blog Instellingen",
        "Body color": "Tekst kleur",
        "Bold": "Dikgedrukt",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Button corners": "Knop hoeken",
        "Button label": "Knoplabel",
        "Button only shows up if module is set to active": "Knop verschijnt alleen als module is ingesteld op actief",
        "Buttons": "Knoppen",
        "Checkout page": "Afrekenpagina",
        "Choose file": "Kies bestand",
        "City": "Stad",
        "Client": "Cliënt",
        "Client Closed Request": "Klant Gesloten Verzoek",
        "Clients": "Cliënten",
        "Close conversation": "sluit gesprek",
        "closed": "gesloten",
        "code": "code",
        "Comment": "Commentaar",
        "Company name": "Bedrijfsnaam",
        "Complete": "Compleet",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in alleen zichtbaar voor ingelogde gebruikers, draft is voor niemand zichtbaar",
        "Conflict": "Conflict",
        "Connection Closed Without Response": "Verbinding gesloten zonder reactie",
        "Connection Timed Out": "Connectie duurt te lang",
        "Consultant": "Consultant",
        "Contact": "Contact",
        "Contact form": "Contactformulier",
        "Contactform": "Contactformulier",
        "Content": "Inhoud",
        "Continue": "Doorgaan",
        "Conversation": "Gesprek",
        "Conversations": "Gesprekken",
        "Counter": "Tegen",
        "Counter number": "Teller",
        "Counters": "Tellers",
        "Counter Short description": "Teller Korte beschrijving",
        "Counter title": "Titel",
        "create": "maak aan",
        "Create {model}": "Maak {model}",
        "Create a password": "Maak een wachtwoord aan",
        "Created": "Aangemaakt",
        "created": "aangemaakt",
        "Created At": "Gemaakt op",
        "Created at": "Gemaakt op",
        "Create follow-up": "Follow-up creëren",
        "Create order": "Bestel",
        "Custom": "Aangepast",
        "Custom background color": "Aangepaste achtergrondkleur",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Klanten krijgen de goedkoopste toepasselijke verzendkosten van elke groep te zien. Momenteel bestaande groepen: {groups}",
        "Custom text color": "Aangepaste tekstkleur",
        "Custom title": "Aangepaste titel",
        "Dangerously high": "Gevaarlijk hoog",
        "Dangerously high explanation": "Gevaarlijk hoge uitleg",
        "Dangerously Low": "Gevaarlijk laag",
        "Dangerously low explanation": "Gevaarlijk lage verklaring",
        "Date": "Datum",
        "Date of birth": "Geboortedatum",
        "Deactivate": "Deactiveer",
        "Deactivated": "gedeactiveerd",
        "deactivated": "gedeactiveerd",
        "Default consultant": "Standaard consulent",
        "Delete": "Verwijder",
        "delete": "verwijder",
        "deleted": "verwijderd",
        "Delete filters": "Filters verwijderen",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Desktop": "Desktop",
        "Details": "Details",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "Discount code": "Kortingscode",
        "done": "klaar",
        "Down": "Down",
        "Download app": "App downloaden",
        "Download backup codes and continue.": "Download back-up codes en ga verder.",
        "Download PDF button name": "Knopnaam PDF downloaden",
        "Due at": "Toe doen op",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "Edit": "Bewerk",
        "edit": "bewerk",
        "Edit {model}": "{model} bewerken",
        "Edit account": "Account bewerken",
        "Email": "E-mail",
        "email": "e-mail",
        "Employees": "Werknemers",
        "Employers": "Werkgevers",
        "employers": "werkgevers",
        "End": "Einde",
        "Enter working hours here": "Voer hier de werkuren in",
        "Excerpt": "Uittreksel",
        "excerpt": "uittreksel",
        "Expectation Failed": "Niet voldaan aan verwachting",
        "External Test": "Externe test",
        "External Tester": "Extern testapparaat",
        "External URL": "Externe URL",
        "Facebook url": "Facebook-adres",
        "Failed Dependency": "Gefaalde afhankelijkheid",
        "Faq": "Faq",
        "Faqoverview": "Faqoverzicht",
        "File": "Bestand",
        "Fill in the 6-digit code": "Vul de 6-cijferige code in",
        "First name": "Voornaam",
        "First name *": "Voornaam *",
        "Fixed Cta": "Vaste Cta",
        "Follow up to": "Vervolg op",
        "Follow up tot": "Opvolging tot",
        "Font": "Lettertype",
        "Font size": "Lettertype grootte",
        "Font weight": "Lettertype style",
        "Footer background color": "Achtergrondkleur voettekst",
        "Footer bottom right": "Voettekst rechtsonder",
        "Footer color": "Kleur voettekst",
        "Footer contact title": "Titel contactpersoon voettekst",
        "Footer opening hours title (the middle one)": "Titel openingstijden voettekst (de middelste)",
        "Footer socials text": "Footer socials tekst",
        "Footer socials title": "Titel socials in voettekst",
        "Footer socials title (the last one)": "Footer socials titel (de laatste)",
        "Footer text color": "Tekstkleur voettekst",
        "Forbidden": "Verboden toegang",
        "Found": "Gevonden",
        "Frequently asked questions": "Veelgestelde vragen",
        "From the authenticator app": "Vanuit de authenticator app",
        "Fully rounded": "Volledig afgerond",
        "Gallery": "Galerie",
        "Gateway Timeout": "Gateway-time-out",
        "Global": "Algemeen",
        "Gone": "Verdwenen",
        "Google Authenticator": "Google Authenticator",
        "Google Maps": "Google-kaarten",
        "Google Maps Iframe": "Google Maps Iframe",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Ga naar pagina {page}",
        "Header Opening hours": "Header Openingstijden",
        "Heading": "Rubriek",
        "Heading 1 is the main title and will be used on every page once": "Kop 1 is de hoofdtitel en wordt op elke pagina één keer gebruikt",
        "Heading 2 are the subtitles and can be used multiple times on a page": "Kop 2 zijn de ondertitels en kunnen meerdere keren op een pagina gebruikt worden",
        "Heading color": "Kleur kop",
        "Hello": "Hallo",
        "Hello!": "Hallo!",
        "Here you can turn on extra modules that are not included in the standard basic MediaMix template. Those are paid modules.": "Hier kun je extra modules inschakelen die niet in de standaard basis MediaMix-sjabloon zitten. Dit zijn betaalde modules.",
        "High": "Hoog",
        "Highest": "Hoogste",
        "High explanation": "Hoge uitleg",
        "Hits": "Hits",
        "Home Banner Slider Variant": "Home Banner schuifregelaar variant",
        "hour": "uur",
        "Hours": "Uren",
        "https://nl.linkedin.com/company/strixi": "https://nl.linkedin.com/company/strixi",
        "https://www.facebook.com/strixi.nl/": "https://www.facebook.com/strixi.nl/",
        "https://www.instagram.com/strixi.nl/": "https://www.instagram.com/strixi.nl/",
        "HTTP Version Not Supported": "HTTP-versie wordt niet ondersteund",
        "I'm a teapot": "Ik ben een theepot",
        "I agree with the": "Ik ga akkoord met de",
        "I agree with the terms and conditions*": "Ik ga akkoord met de algemene voorwaarden",
        "Icon": "Pictogram",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "I have saved the backup codes": "Ik heb de back-up codes opgeslagen",
        "Image": "Afbeelding",
        "IM Used": "Ik ben gebruikt",
        "Including VAT": "Inclusief BTW",
        "Index Page": "Indexpagina",
        "Ingredients": "Ingrediënten",
        "Inloggen": "Inloggen",
        "Inspection": "Inspectie",
        "Instagram url": "Instagram-adres",
        "Instruction title": "Titel van de instructie",
        "Insufficient Storage": "Onvoldoende opslag",
        "Internal comment": "Interne opmerking",
        "Internal comments": "Interne opmerkingen",
        "Internal Server Error": "Interne serverfout",
        "Introduction": "Inleiding",
        "Invalid phone number": "Ongeldig telefoonnummer",
        "Invalid SSL Certificate": "Ongeldig SSL-certificaat",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribuut} tussen {min} en {max} tekens lang te houden.",
        "Job position": "Functie",
        "Kind regards,": "Met vriendelijke groet,",
        "Label Button": "Labelknop",
        "Last message on": "Laatste bericht op",
        "Last name": "Achternaam",
        "Last name *": "Achternaam *",
        "Last refresh was {quantity} {unit} ago": "Laaste verversing was {quantity} {unit} geleden",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "Length Required": "Lengte vereist",
        "Light": "Licht",
        "Line height": "Lijnhoogte",
        "Link Button": "Koppelingsknop",
        "LinkedIn url": "LinkedIn-adres",
        "Locked": "Afgesloten",
        "Login": "Inloggen",
        "Logo height": "Hoogte logo",
        "Logo slider": "Logo schuifbalk",
        "Logoslider": "Logoschuif",
        "Logout": "Uitloggen",
        "Loop Detected": "Loop gedetecteerd",
        "Low": "Laag",
        "Lowest": "Laagste",
        "Low explanation": "Lage uitleg",
        "Made by:": "Gerealiseerd door:",
        "Maintenance Mode": "Dienst niet beschikbaar",
        "Mark as done": "Markeren als gedaan",
        "Medical": "Medisch",
        "Medium": "Medium",
        "Menu Card": "Menukaart",
        "Menucard": "Menucard",
        "Menu Card Item": "Menukaart-item",
        "Menu items": "Menu-items",
        "Menu Title": "Menu Titel",
        "Message": "Bericht",
        "Message *": "Bericht",
        "Messages": "Berichten",
        "Method Not Allowed": "Methode niet toegestaan",
        "Metric Name": "Meetwaarden naam",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "minus icon": "minpictogram",
        "minute": "minuut",
        "minutes": "minuten",
        "Misdirected Request": "Verkeerd geadresseerd verzoek",
        "Mobile": "Mobiel",
        "Modules": "Modules",
        "More information": "Meer informatie",
        "More settings can be set in the Page Block Settings page": "Meer instellingen kunnen worden ingesteld op de pagina Instellingen Paginablokkering",
        "Moved Permanently": "Definitief verplaatst",
        "Multi-Status": "Meerdere statussen",
        "Multiple Choices": "Meerkeuze",
        "MUST START with 31, folliwing with number WITHOUT 0. Otherwise it won't work!": "MOET beginnen met 31, gevolgd door een getal ZONDER 0. Anders werkt het niet!",
        "Mute client": "Mute client",
        "Muted": "Gedempt",
        "muted": "gedempt",
        "Name": "Naam",
        "name": "naam",
        "Name *": "Naam *",
        "Name client": "Naam cliënt",
        "Network Authentication Required": "Netwerkauthenticatie vereist",
        "Network Connect Timeout Error": "Fout bij time-out netwerkverbinding",
        "Network Read Timeout Error": "Time-outfout netwerk lezen",
        "News": "Nieuws",
        "News Content": "Nieuws",
        "Newsletter": "Nieuwsbrief",
        "New URL": "Nieuwe URL",
        "No": "Geen",
        "No Content": "Geen inhoud",
        "No items": "Geen items",
        "Non-Authoritative Information": "Niet-gemachtigde informatie",
        "None": "Geen",
        "Normal": "Normaal",
        "Not Acceptable": "Niet aanvaardbaar",
        "Not Extended": "Niet verlengd",
        "Not Found": "Niet gevonden",
        "Not Implemented": "Niet geïmplementeerd",
        "Not implemented yet": "Nog niet geïmplementeerd",
        "Not Modified": "Niet gewijzigd",
        "of": "van",
        "OK": "Oké",
        "Old URL": "Oude URL",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "Op uw telefoon of tablet met camera. Wij kunnen u adviseren om {google} of {microsoft} te gebruiken.",
        "Opening hours": "Openingstijden",
        "Openings hours": "Openingstijden",
        "Open the authenticator app, add a new entry and scan the QR code": "Open de authenticator-app, voeg een nieuw item toe en scan de QR-code",
        "Order": "Bestel",
        "Origin Is Unreachable": "Herkomst is onbereikbaar",
        "Overview": "Overzicht",
        "Overwrite title color": "Titelkleur overschrijven",
        "Package name": "Pakket naam",
        "Packages": "Pakketten",
        "Page (within website)": "Pagina (binnen website)",
        "Page blocks": "Pagina blokken",
        "Page Expired": "Pagina niet meer geldig",
        "Page Header": "Pagina Header",
        "PageHeader": "PageHeader",
        "Page Title": "Pagina Titel",
        "Pagination Navigation": "Paginanavigatie",
        "Paragraph": "Paragraaf",
        "Parent category": "Hoofdcategorie",
        "Partial Content": "Gedeeltelijke inhoud",
        "Password": "Wachtwoord",
        "Paste in here only the Youtube ID. That id the ID what you see in the url in the browser after https://www.youtube.com/watch?v=": "Plak hier alleen de Youtube ID in. Dat id het ID wat je ziet in de url in de browser na https://www.youtube.com/watch?v=",
        "Paste or type the code we sent a code to your e-mail.": "Plak of typ de code die we naar je e-mail hebben gestuurd.",
        "Paste or type the code we sent a code to your phone number.": "Plak of typ de code die we naar je telefoonnummer hebben gestuurd.",
        "Payload Too Large": "Aanvraag te groot",
        "Payment Required": "Betaalde toegang",
        "PDF Menu cards": "PDF Menukaarten",
        "Permanent": "Permanent",
        "Permanent Redirect": "Definitieve omleiding",
        "Phone": "Telefoon",
        "Phone *": "Telefoonnummer *",
        "Phone number": "Telefoonnummer",
        "phone number": "telefoonnummer",
        "phone_number": "telefoonnummer",
        "Place": "Woonplaats",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "plus icon": "plus icoon",
        "Postal code": "Postcode",
        "Postcode": "Postcode",
        "Precondition Failed": "Niet voldaan aan de vooraf gestelde voorwaarde",
        "Precondition Required": "Voorwaarde nodig",
        "Price": "Prijs",
        "Price frequency": "Prijsfrequentie",
        "Primary": "Primair",
        "Primary Color": "Primaire kleur",
        "Primary Color (on dark backgrounds)": "Primaire kleur (op donkere achtergronden)",
        "Primary Hover Color": "Primaire hoverkleur",
        "Primary Hover Color (on dark backgrounds)": "Primaire hoverkleur (op donkere achtergronden)",
        "Primary text color": "Primaire tekstkleur",
        "Primary text color (on dark backgrounds)": "Primaire tekstkleur (op donkere achtergronden)",
        "Print": "Afdrukken",
        "Priority": "Prioriteit",
        "Privacy declaration": "Privacyverklaring",
        "privacy statement": "privacyverklaring",
        "Processing": "Verwerken",
        "Product": "Product",
        "Products": "Producten",
        "Project Content": "Inhoud project",
        "Projects": "Projecten",
        "Proxy Authentication Required": "Authenticatie op de proxyserver verplicht",
        "Question": "Vraag",
        "Railgun Error": "Railgun foutmelding",
        "Range Not Satisfiable": "Bereik niet bevredigend",
        "Reactivate": "Reactiveren",
        "Read more": "Lees meer",
        "Regards": "Met vriendelijke groet",
        "Register": "Registreren",
        "Regular": "Normaal",
        "Related products": "Verwante producten",
        "Remove": "Verwijder",
        "Remove discount code": "Kortingscode verwijderen",
        "Reopened subtask": "Heropende subtaak",
        "Request Header Fields Too Large": "Headers van de aanvraag te lang",
        "Request Timeout": "Aanvraagtijd verstreken",
        "Resend {attribute}": "Verstuur {attribute} opnieuw",
        "Reserved By": "Gereserveerd door",
        "Reset Content": "Inhoud opnieuw instellen",
        "Reset Password": "Wachtwoord herstellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "Results": "Resultaten",
        "results": "resultaten",
        "Results are shared": "De resultaten worden gedeeld",
        "Retry With": "Opnieuw proberen met",
        "Review": "Review",
        "Reviews": "Beoordelingen",
        "Review Slider": "Review Slider",
        "Review slider": "Beoordelingsschuif",
        "Reviewslider": "Reviewslider",
        "Role": "Rol",
        "role": "rol",
        "Roles": "Rollen",
        "Rounded": "Afgerond",
        "Sampled at": "Afgenomen op",
        "Save": "Opslaan",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Bewaar deze codes op een veilige plek als je geen toegang krijgt met je Authenticator app.",
        "Scanning not possible? Fill in this code in the app.": "Scannen niet mogelijk? Vul deze code in de app in.",
        "Scan the QR code": "Scan de QR-code",
        "Search": "Zoek op",
        "Secondary": "Secundair",
        "Secondary Color": "Secundaire kleur",
        "See all faqs": "Bekijk alle vragen",
        "See Other": "Zie andere",
        "Select a {model}": "Selecteer een {model}",
        "Select a template to add the corresponding blocks.": "Selecteer een sjabloon om de bijbehorende blokken toe te voegen.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Selecteer een template om de bijbehorende blokken toe te voegen. Let op: dit kan niet worden gewijzigd nadat de pagina is aangemaakt.",
        "Select a test": "Selecteer een test",
        "Select a type": "Selecteer een type",
        "Send application": "Verstuur sollicitatie",
        "SEO Description": "SEO Beschrijving",
        "SEO Settings for {lang}": "SEO instellingen voor {lang}",
        "SEO title": "SEO titel",
        "Server Error": "Server fout",
        "Services": "Diensten",
        "Service Unavailable": "Website onbeschikbaar",
        "Session Has Expired": "Pagina verlopen",
        "Set all titles to uppercase": "Maak alle titels hoofdletters",
        "Set color for the footer.": "Kleur voor de voettekst instellen.",
        "Set color for the footer background": "Kleur instellen voor de achtergrond van de voettekst",
        "Set color for the footer text": "Kleur instellen voor de voettekst",
        "Sets": "Zet",
        "Set Two Factor Authentication": "Twee Factor Authenticatie instellen",
        "shared": "gedeeld",
        "Share results": "Resultaten delen",
        "Shipping address same as billing address": "Verzendadres hetzelfde als factuuradres",
        "Shipping method": "Verzendmethode",
        "Shop": "Winkel",
        "Shop not finished yet...": "Winkel nog niet klaar...",
        "Shopping cart": "Winkelwagen",
        "shopping cart": "winkelwagen",
        "Showing": "Toont",
        "Show opening hours in header": "Toon openingstijden in koptekst",
        "Show phone number in header": "Toon telefoonnummer in koptekst",
        "Sign out": "Afmelden",
        "Slug": "Permalink",
        "Social media": "Sociale media",
        "SSL Handshake Failed": "SSL-handshake mislukt",
        "Stars": "Sterren",
        "Start": "Start",
        "Start date:": "Startdatum",
        "Started at": "Gestart op",
        "Status": "Status",
        "Statuses": "Statussen",
        "Street name": "Straatnaam",
        "Street number": "Huisnummer",
        "Street number addition": "Toevoeging",
        "Subject *": "Onderwerp *",
        "Submit": "Verzenden",
        "SubTasks": "Subtaken",
        "Subtotal": "Subtotaal",
        "Switching Protocols": "Protocolwissel",
        "Task": "Taak",
        "Tasks": "Taken",
        "Team": "Team",
        "Temporary Redirect": "Tijdelijke omleiding",
        "Terms and Conditions": "Algemene Voorwaarden",
        "terms and conditions": "algemene voorwaarden",
        "Tertiary": "Tertiair",
        "Tertiary Color": "Tertiaire kleur",
        "Test": "Test",
        "test": "test",
        "Tests": "Tests",
        "Test set": "Testreeks",
        "test set": "testreeks",
        "Test sets": "Testreeksen",
        "Text": "Tekst",
        "Text transform": "Tekst transformeren",
        "Thank you for your application!": "Bedankt voor je sollicitatie!",
        "Thank you for your message!": "Bedankt voor je bericht!",
        "Thank you for your sign up!": "Bedankt voor je aanmelding!",
        "The {attribute} has already been taken.": "Het {attribuut} is al ingenomen.",
        "The {attribute} must be verified.": "Het {attribuut} moet worden geverifieerd.",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The coupon has already been used.": "De coupon is al gebruikt.",
        "The coupon has expired.": "De coupon is verlopen.",
        "The font size for the main / body font.": "De lettergrootte voor het hoofdlettertype.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The provided {attribute} is invalid.": "Het verstrekte {attribuut} is ongeldig.",
        "The provided code was invalid.": "De verstrekte code was ongeldig.",
        "The provided password does not match your current password.": "Het opgegeven wachtwoord komt niet overeen met uw huidige wachtwoord.",
        "The provided two factor authentication code was invalid.": "De verstrekte tweefactorauthenticatiecode was ongeldig.",
        "The styling for all mails like contactform, newsletter etc.": "De styling voor alle mails zoals contactformulier, nieuwsbrief enz.",
        "The tests cannot be changed once the test set is created.": "De tests kunnen niet worden gewijzigd nadat de testreeks is aangemaakt.",
        "The text color for primary elements, like buttons": "De tekstkleur voor primaire elementen, zoals knoppen",
        "The text color for primary elements, like buttons on a dark background": "De tekstkleur voor primaire elementen, zoals knoppen op een donkere achtergrond",
        "The thickness of the font that will be displayed": "De dikte van het lettertype dat wordt weergegeven",
        "The thickness of the font that will be displayed for all heading titles": "De dikte van het lettertype dat wordt weergegeven voor alle koptitels",
        "This {attribute} has already been used": "Dit {attribuut} is al gebruikt",
        "This area is restricted to medical medic members only.": "Dit gebied is alleen toegankelijk voor medische leden.",
        "This area is restricted to staff members only.": "Dit gebied is alleen toegankelijk voor personeelsleden.",
        "This font will be used as main / body font.": "Dit lettertype wordt gebruikt als hoofdlettertype.",
        "This font will be used for all heading titles H1, H2, H3. H4, H5, H6": "Dit lettertype wordt gebruikt voor alle koptitels H1, H2, H3. H4, H5, H6",
        "This is your first login": "Dit is uw eerste login",
        "This line must be as short as possible, otherwise the menu items won't fit at 1 line": "Deze regel moet zo kort mogelijk zijn, anders passen de menu-items niet op 1 regel",
        "This link expires in {number} hours": "Deze link verloopt over {number} uur",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "This task is reserved by {userName}": "Deze task is gereserveerd door {userName}",
        "This task is reserved by {userName}.": "Deze task is gereserveerd door {userName}.",
        "Title": "Titel",
        "title": "titel",
        "to": "tot",
        "TODO": "TODO",
        "Toggle navigation": "Schakel navigatie",
        "token": "token",
        "Too Early": "Te vroeg",
        "Too Many Requests": "Te veel requests",
        "Total": "Totaal",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "Om Topchefs te gebruiken is Two Factor Authentication nodig als extra beveiliging, u kunt dit nu instellen",
        "Trans": "Trans",
        "Two Factor Authentication - Backup codes": "Twee Factor Authenticatie - Back-up codes",
        "Type": "Type",
        "Type job position": "Type functie",
        "Unauthorized": "Niet geautoriseerd",
        "Unavailable For Legal Reasons": "Toegang geweigerd om juridische redenen",
        "Underline": "Onderstreept",
        "Unknown Error": "Onbekende foutmelding",
        "Unmute client": "Ontdemp client",
        "unmuted": "ontdempt",
        "Unprocessable Entity": "Aanvraag kan niet worden verwerkt",
        "Unsupported Media Type": "Media-type niet ondersteund",
        "Up": "Omhoog",
        "Update": "Bijwerken",
        "updated": "bijgewerkt",
        "Upgrade Required": "Upgrade nodig",
        "Upload C.V *": "Upload C.V *",
        "Upload Motivation *": "Upload Motivatie *",
        "Uppercase": "Hoofdletters",
        "URI Too Long": "Aanvraag-URL te lang",
        "Url": "Url",
        "url": "url",
        "Use Proxy": "Gebruik Proxy",
        "User": "Gebruiker",
        "user": "gebruiker",
        "Users": "Gebruikers",
        "users": "gebruikers",
        "USPs": "USP's",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Vacancies": "Vacatures",
        "Vacancies Overview": "Overzicht vacatures",
        "Vacancy application form": "Vacature sollicitatieformulier",
        "Vacancy Content": "Vacature content",
        "Vacancyfilters": "Vacature filters",
        "Vacancy intro": "Vacature intro",
        "Vacancy recent": "Recente vacature",
        "Variant Also Negotiates": "Variant onderhandelt ook",
        "Variants": "Varianten",
        "VAT": "BTW",
        "Verify": "Controleer",
        "Verify {attribute}": "Verifieer {attribute}",
        "Verify Email Address": "Verifieer e-mailadres",
        "Videos": "Videos",
        "View": "Bekijk",
        "view": "bekijk",
        "View all": "Bekijk alles",
        "view all": "bekijk alles",
        "View project": "Bekijk project",
        "View vacancy": "Bekijk vacature",
        "Web Page": "Website",
        "Web Page Settings": "Instellingen webpagina",
        "Web Server is Down": "Webserver is onbereikbaar",
        "weekly": "wekelijks",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We hebben uw bericht ontvangen. Een van onze medewerkers neemt zo snel mogelijk contact met u op.",
        "Welcome": "Welkom",
        "Welcome to {page}": "Welkom op {page}",
        "WhatsApp Button": "WhatsApp-knop",
        "Whatsapp number": "Whatsapp nummer",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "Indien ingeschakeld, wordt een e-mail naar de gebruiker gestuurd om hem te laten weten dat zijn account is aangemaakt.",
        "White": "Wit",
        "Whoops!": "Oeps!",
        "Will be used as color for the titles": "Wordt gebruikt als kleur voor de titels",
        "Will be used as hover main color for buttons, links, elements or components with a background color": "Wordt gebruikt als hover-hoofdkleur voor knoppen, links, elementen of onderdelen met een achtergrondkleur",
        "Will be used as hover main color on a dark background": "Wordt gebruikt als hover-hoofdkleur op een donkere achtergrond",
        "Will be used as main color for buttons, links, elements or components with a background color": "Wordt gebruikt als hoofdkleur voor knoppen, links, elementen of onderdelen met een achtergrondkleur",
        "Will be used as main color for the body text": "Wordt gebruikt als hoofdkleur voor de bodytekst",
        "Will be used as main color on a dark background": "Wordt gebruikt als hoofdkleur op een donkere achtergrond",
        "Yes": "Ja",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "You can contact us via the form. We look forward to hearing from you. Have you looked at the frequently asked questions? With the exception of weekends and holidays, we try to send you a response to your questions within 24 hours.": "U kunt contact met ons opnemen via het formulier. We horen graag van u. Heeft u gekeken naar de veelgestelde vragen? Met uitzondering van weekenden en feestdagen proberen we binnen 24 uur een reactie op uw vragen te sturen.",
        "Your account for {siteName}": "Je account voor {siteName}",
        "Your account is inactive. Please contact support.": "Uw account is inactief. Neem contact op met support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Uw account is inactief. Neem contact op met support. Om de website als gast te gebruiken, vernieuwt u de pagina.",
        "Your current locale is {locale}": "Uw huidige locale is {locale}",
        "Your last login was {relativeTime} on {dateTime}": "Uw laatste login was {relativeTime} op {dateTime}",
        "Your Order": "Uw bestelling",
        "Your order": "Uw bestelling",
        "Your Profile": "Uw profiel",
        "Youtube": "Youtube",
        "Youtube Video ID": "Youtube-video-ID",
        "You will receive this email because your account is ready for {siteName}": "Je ontvangt deze e-mail omdat je account klaar is voor {siteName}",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "ascii": "De {attribute} mag alleen alfanumerieke tekens en symbolen van één byte bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "decimal": "De {attribute} moet {decimal} decimalen hebben.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "{Attribute} mag niet eindigen met één van de volgende waarden: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "{Attribute} mag alleen kleine letters bevatten.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "{Attribute} mag niet uit meer dan {max} cijfers bestaan.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "{Attribute} moet minimaal uit {min} cijfers bestaan.",
            "missing": "Het veld {attribute} moet ontbreken.",
            "missing_if": "Het veld {attribute} moet ontbreken als {other} {value} is.",
            "missing_unless": "Het veld {attribute} moet ontbreken, tenzij {other} {value} is.",
            "missing_with": "Het veld {attribute} moet ontbreken wanneer {values} aanwezig is.",
            "missing_with_all": "Het veld {attribute} moet ontbreken wanneer er {values} aanwezig zijn.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "{Attribute} is verplicht indien {other} is geaccepteerd.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "ulid": "De {attribute} moet een geldige ULID zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "{Attribute} mag alleen hoofdletters bevatten.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "affiliate_url": "partner-URL",
                "age": "leeftijd",
                "amount": "bedrag",
                "announcement": "aankondiging",
                "area": "gebied",
                "audience_prize": "publieksprijs",
                "audience_winner": "audience winner",
                "available": "beschikbaar",
                "billing_address.city": "Plaats",
                "billing_address.company_name": "Bedrijfsnaam",
                "billing_address.country": "Land",
                "billing_address.postcode": "Postcode",
                "billing_address.street_name": "Straatnaam",
                "billing_address.street_number": "Huisnummer",
                "billing_address.street_number_addition": "Toevoeging",
                "billing_address.vat_number": "BTW nummer",
                "birthday": "verjaardag",
                "body": "inhoud",
                "city": "stad",
                "company": "company",
                "compilation": "compilatie",
                "concept": "concept",
                "conditions": "voorwaarden",
                "consultant_id": "Consultant",
                "content": "inhoud",
                "contest": "contest",
                "country": "land",
                "cover": "omslag",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "currency": "valuta",
                "current_password": "huidig wachtwoord",
                "customer": "klant",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "dates": "datums",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "display_type": "weergavetype",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "finished_at": "klaar om",
                "first_name": "voornaam",
                "gender": "geslacht",
                "grand_prize": "grote prijs",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "image_desktop": "bureaubladafbeelding",
                "image_main": "hoofdafbeelding",
                "image_mobile": "mobiele afbeelding",
                "images": "afbeeldingen",
                "is_audience_winner": "is publiekswinnaar",
                "is_hidden": "is verborgen",
                "is_subscribed": "is geabonneerd",
                "is_visible": "is zichtbaar",
                "is_winner": "is winnaar",
                "items": "artikels",
                "key": "sleutel",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "login": "login",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "portfolio": "portfolio",
                "postal_code": "postcode",
                "preview": "voorbeeld",
                "price": "prijs",
                "product_id": "product-ID",
                "product_uid": "product-UID",
                "product_uuid": "product-UUID",
                "promo_code": "promo-code",
                "province": "provincie",
                "quantity": "hoeveelheid",
                "reason": "reden",
                "recaptcha_response_field": "recaptcha-antwoordveld",
                "referee": "scheidsrechter",
                "referees": "scheidsrechters",
                "reject_reason": "afwijsreden",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "role_id": "rol",
                "rule": "regel",
                "rules": "regels",
                "second": "seconde",
                "sex": "geslacht",
                "shipment": "verzending",
                "shipping_address.city": "Plaats",
                "shipping_address.postcode": "Postcode",
                "shipping_address.street_name": "Straatnaam",
                "shipping_address.street_number": "Huisnummer",
                "shipping_address.street_number_addition": "Toevoeging",
                "shipping_rate_id": "Verzendmethode",
                "short_text": "korte tekst",
                "size": "grootte",
                "skills": "vaardigheden",
                "slug": "slug",
                "specialization": "specialisatie",
                "started_at": "gestart op",
                "state": "staat",
                "status": "status",
                "street": "straat",
                "student": "leerling",
                "subject": "onderwerp",
                "tag": "label",
                "tags": "labels",
                "teacher": "docent",
                "terms": "voorwaarden",
                "terms_and_conditions": "Algemene voorwaarden en privacy verklaring",
                "test_description": "testomschrijving",
                "test_locale": "testlandinstelling",
                "test_name": "testnaam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "type": "type",
                "updated_at": "bijgewerkt op",
                "user": "gebruiker",
                "user_id": "gebruiker",
                "user_type": "gebruikerstype",
                "username": "gebruikersnaam",
                "value": "waarde",
                "winner": "winner",
                "work": "work",
                "year": "jaar"
            }
        },
        "routes": {
            "frequently-asked-questions": "veelgestelde-vragen",
            "blog": "blog",
            "projects": "projecten",
            "about-us": "over-ons",
            "general-terms": "algemene-voorwaarden",
            "products": "producten",
            "privacy-policy": "privacy-beleid",
            "cookie-policy": "cookie-beleid",
            "contact": "contact",
            "search": "zoeken",
            "vacancies": "vacatures",
            "faq": "faq",
            "team": "team",
            "news": "nieuws"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "http-statuses": {
            "0": "Onbekende foutmelding",
            "100": "Doorgaan",
            "101": "Protocolwissel",
            "102": "Verwerken",
            "200": "Oké",
            "201": "Aangemaakt",
            "202": "Aanvaard",
            "203": "Niet-gemachtigde informatie",
            "204": "Geen inhoud",
            "205": "Inhoud opnieuw instellen",
            "206": "Gedeeltelijke inhoud",
            "207": "Meerdere statussen",
            "208": "Al gemeld",
            "226": "Ik ben gebruikt",
            "300": "Meerkeuze",
            "301": "Definitief verplaatst",
            "302": "Gevonden",
            "303": "Zie andere",
            "304": "Niet gewijzigd",
            "305": "Gebruik Proxy",
            "307": "Tijdelijke omleiding",
            "308": "Definitieve omleiding",
            "400": "Foute aanvraag",
            "401": "Niet geautoriseerd",
            "402": "Betaalde toegang",
            "403": "Verboden toegang",
            "404": "Niet gevonden",
            "405": "Methode niet toegestaan",
            "406": "Niet aanvaardbaar",
            "407": "Authenticatie op de proxyserver verplicht",
            "408": "Aanvraagtijd verstreken",
            "409": "Conflict",
            "410": "Verdwenen",
            "411": "Lengte vereist",
            "412": "Niet voldaan aan de vooraf gestelde voorwaarde",
            "413": "Aanvraag te groot",
            "414": "Aanvraag-URL te lang",
            "415": "Media-type niet ondersteund",
            "416": "Bereik niet bevredigend",
            "417": "Niet voldaan aan verwachting",
            "418": "Ik ben een theepot",
            "419": "Pagina verlopen",
            "421": "Verkeerd geadresseerd verzoek",
            "422": "Aanvraag kan niet worden verwerkt",
            "423": "Afgesloten",
            "424": "Gefaalde afhankelijkheid",
            "425": "Te vroeg",
            "426": "Upgrade nodig",
            "428": "Voorwaarde nodig",
            "429": "Te veel requests",
            "431": "Headers van de aanvraag te lang",
            "444": "Verbinding gesloten zonder reactie",
            "449": "Opnieuw proberen met",
            "451": "Toegang geweigerd om juridische redenen",
            "499": "Klant Gesloten Verzoek",
            "500": "Interne serverfout",
            "501": "Niet geïmplementeerd",
            "502": "Slechte poort",
            "503": "Dienst niet beschikbaar",
            "504": "Gateway-time-out",
            "505": "HTTP-versie wordt niet ondersteund",
            "506": "Variant onderhandelt ook",
            "507": "Onvoldoende opslag",
            "508": "Loop gedetecteerd",
            "509": "Bandbreedte overschreden",
            "510": "Niet verlengd",
            "511": "Netwerkauthenticatie vereist",
            "520": "Onbekende foutmelding",
            "521": "Webserver is onbereikbaar",
            "522": "Connectie duurt te lang",
            "523": "Herkomst is onbereikbaar",
            "524": "Time-out opgetreden",
            "525": "SSL-handshake mislukt",
            "526": "Ongeldig SSL-certificaat",
            "527": "Railgun foutmelding",
            "598": "Time-outfout netwerk lezen",
            "599": "Fout bij time-out netwerkverbinding",
            "unknownError": "Onbekende foutmelding"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen."
        }
    }
}
